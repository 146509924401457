import React from 'react'
import s from './Header.module.css'
import { Link } from 'react-router-dom'

export const Header = () => {
    return (
        <header className={s.header}>
            <div className={s.header__container}>
                <div className={s.header__wrapper}>
                    <h2 className={s.header__title}>
                        <Link to="/" className={`tracking-in-expand ${s.header__link}`}>
                            <span>C</span>Dream
                        </Link>
                    </h2>
                </div>
            </div>
        </header>
    )
}
