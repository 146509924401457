import React from "react";
import s from "./Home.module.css";
import { Card } from "./Card/Card";
import arrowGreen from "../../../assets/Home/arrowGreen.svg";
import arrowPurple from "../../../assets/Home/arrowPurple.svg";
import arrowBlue from "../../../assets/Home/arrowBlue.svg";
import flowco from "../../../assets/Home/flowco.png";
import coflow from "../../../assets/Home/coflow.png";
import habit from "../../../assets/Home/habit.png";
import joblio from "../../../assets/Home/joblio.png";
import star from "../../../assets/Home/star.svg";
import lights from "../../../assets/Home/lights.svg";
import lm from "../../../assets/Home/lm.png";
import williams from "../../../assets/Home/williams.png";
import louis from "../../../assets/Home/louis.png";
import koyap from "../../../assets/Home/koyap.png";
import filter from "../../../assets/Home/filter.png";
import childfree from "../../../assets/Home/childfree.png";
import securigeek from "../../../assets/Home/securigeek.png";
import pet from "../../../assets/Home/pet.png";
import sport from "../../../assets/Home/sport.png";

import { Link as AnchorLink, Element } from "react-scroll";
import { IProject } from "../../../types/project";

export const HomePage = () => {
  const projectsReact: IProject[] = [
    {
      img: flowco,
      title: "Flowco",
      description:
        "Embark on a journey to support our beloved blogger as they pursue their creative endeavors! Our fundraising project offers exclusive merchandise for purchase, allowing fans to directly finance the blogger's passion for crafting inspiring content.",
      link: "https://flowco.vercel.app/",
    },
    {
      img: coflow,
      title: "Influencer dashboard",
      description:
        "Gain valuable insights and track the performance. Monitor orders, finances, user engagement, and identify top contributors, all in one centralized platform.",
      link: "https://coflow-dashboard.vercel.app/",
    },
    {
      img: sport,
      title: "Sports Genie",
      description:
        "Gain valuable insights and track the performance. Monitor orders, finances, user engagement, and identify top contributors, all in one centralized platform.",
      link: "https://events-sport.vercel.app/",
    },
  ];

  const projectsWebflow: IProject[] = [
    {
      img: habit,
      title: "Habit Ruler",
      description:
        "Our unique sleep website helps you get up earlier and improve productivity. Learn how to turn sleep time into money, empowering you to achieve your goals with every sunrise.",
      link: "https://habit-ruler.webflow.io",
    },
    {
      img: joblio,
      title: "JOBLIO",
      description:
        "Say goodbye to tedious job board applications and costly recruiters – our platform seamlessly connects you with skilled individuals, ensuring your job openings reach the right candidates efficiently and hassle-free.",
      link: "https://jobl-io.webflow.io",
    },
    {
      img: williams,
      title: "Williams",
      description:
        "Explore the dynamic world of entrepreneur and investor Dave Williams, whose 20-year journey from corporate lawyer to industry maven has paved the way for success.",
      link: "https://dave-williams.webflow.io",
    },
    {
      img: lm,
      title: "LM Studio",
      description:
        " From captivating content creation to strategic website optimization, video production, and powerful social media marketing, here is your one-stop destination for elevating your brand's online presence and driving impactful engagement.",
      link: "https://lmstudio.webflow.io",
    },
    {
      img: louis,
      title: "Louis Montgomery",
      description:
        "Take care of your business today so you can enjoy a happy life tomorrow. On this site, great opportunities for a rich life are opening up for you.",
      link: "https://luis-montgomery.webflow.io/",
    },
  ];

  const projectsWordpress: IProject[] = [
    {
      img: koyap,
      title: "koyap",
      description:
        "On our travel website, we carefully choose the best deals, making sure that you get the best value possible with a wide range of fun activities, high-end amenities, and unforgettable trips. Start on a trip that will exceed all of your expectations in terms of luxury and excitement.",
      link: "https://koyap.com",
    },
    {
      img: pet,
      title: "Pet Domestic",
      description:
        " Your go-to solution for spotless yards and hassle-free pet waste removal. With flexible scheduling options and reliable service, service cater to both residential and commercial needs, ensuring your outdoor space remains pristine without any inconvenience.",
      link: "https://petdomestic.com",
    },
    {
      img: filter,
      title: "Smart Filter Pro",
      description:
        "Find the perfect match for your smart thermostat with our hassle-free filter replacement service. Stay worry-free and enjoy clean air at home with timely deliveries tailored to your thermostat's needs.",
      link: "https://smartfilterpro.com",
    },
    {
      img: childfree,
      title: "Child Free by choice",
      description:
        "Discover autonomy in reproductive choices with CBC's platform, connecting individuals to donors and physicians for fully funded vasectomy and tubal ligation procedures.",
      link: "http://childfreebc.com/",
    },
    {
      img: securigeek,
      title: "SecuriGeek",
      description:
        "This project represents a pioneering SaaS solution that utilizes AI/ML technology to deliver comprehensive visibility and swift response capabilities to identity-based attacks within cloud infrastructures.",
      link: "https://securigeek.com/",
    },
  ];

  return (
    <>
      <section className={s.header}>
        <div className="header__container">
          <div className={s.header__wrapper}>
            <h1 className={`${s.header__title} text-focus-in`}>
              some hero title will <br /> <span>be here !</span>
            </h1>
            <ul className={s.header__links}>
              <li>
                <AnchorLink
                  className={s.header__link}
                  to="react"
                  spy={true}
                  smooth={true}
                >
                  React
                  <img src={arrowGreen} alt="arrowGreen" />
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className={s.header__link}
                  to="webflow"
                  spy={true}
                  smooth={true}
                >
                  Webflow
                  <img src={arrowPurple} alt="arrowPurple" />
                </AnchorLink>
              </li>
              <li>
                <AnchorLink
                  className={s.header__link}
                  to="wordpress"
                  spy={true}
                  smooth={true}
                >
                  WordPress
                  <img src={arrowBlue} alt="arrowBlue" />
                </AnchorLink>
              </li>
            </ul>
          </div>
        </div>
      </section>
      <div className={s.home}>
        <section className={s.react}>
          <Element name="react"></Element>
          <div className="react__container">
            <h2 className={s.react__title}>React</h2>
            <div className={s.react__wrapper}>
                <Card size="big" project={projectsReact[0]} tech={"react"}></Card>
                <Card size="medium" project={projectsReact[2]} tech={"react"}></Card>
                <Card size="xlarge" project={projectsReact[1]} tech={"react"}></Card>
            </div>
          </div>
        </section>
        <section className={s.webflow}>
          <div className={s.webflow__container}>
            <Element className={s.webflow__wrapper} name="webflow">
              <div className={s.webflow__header}>
                <h2 className={s.webflow__title}>Webflow</h2>
                <Card
                  project={projectsWebflow[0]}
                  tech={"webflow"}
                  size={"small"}
                ></Card>
                <div className={s.webflow_decor}>
                  <img src={star} alt="star" />
                </div>
              </div>
              <Card
                project={projectsWebflow[1]}
                tech={"webflow"}
                size={"large"}
              ></Card>
            </Element>
            <div className={s.webflow__wrapper}>
              <Card
                project={projectsWebflow[2]}
                tech={"webflow"}
                size={"large"}
              ></Card>
              <div className={s.card__up}>
                <Card
                  project={projectsWebflow[3]}
                  tech={"webflow"}
                  size={"medium"}
                ></Card>
              </div>
            </div>
            <Card
              project={projectsWebflow[4]}
              tech={"webflow"}
              size={"xlarge"}
            ></Card>
          </div>
        </section>
        <section className={s.wordpress}>
          <div className={s.wordpress__container}>
            <h2 className={s.wordpress__title_mobile}>Wordpress</h2>
            <Element className={s.wordpress__wrapper} name="wordpress">
              <Card
                project={projectsWordpress[1]}
                tech={"wordpress"}
                size={"large"}
              ></Card>
              <div className={s.wordpress__header}>
                <h2 className={s.wordpress__title}>Wordpress</h2>
                <Card
                  project={projectsWordpress[0]}
                  tech={"wordpress"}
                  size={"small"}
                ></Card>
                <div className={s.wordpress_decor}>
                  <img src={lights} alt="lights" />
                </div>
              </div>
            </Element>
            <div className={s.wordpress__wrapper}>
              <div className={s.card__up}>
                <Card
                  project={projectsWordpress[2]}
                  tech={"wordpress"}
                  size={"medium"}
                ></Card>
              </div>
              <Card
                project={projectsWordpress[3 ]}
                tech={"wordpress"}
                size={"large"}
              ></Card>
            </div>
            <Card
              project={projectsWordpress[4]}
              tech={"wordpress"}
              size={"xlarge"}
            ></Card>
          </div>
        </section>
      </div>
    </>
  );
};
