import React from "react";
import { Header } from "./components/Header/Header";
import { Router } from "./components/Router/Router";
import { Footer } from "./components/Footer/Footer";
import s from "./App.module.css";

function App() {
    return (
        <div className={s.wrapper}>
            <Header />
            <Router />
            <Footer />
        </div>
    );
}

export default App;
