import React from "react";
import { Route, Routes } from "react-router-dom";
import { HomePage } from "../Home/Home";
import s from "./Router.module.css";

export const Router = () => {
    return (
        <main className={s.main}>
            <Routes>
                <Route path="/" element={<HomePage />} />
            </Routes>
        </main>
    );
};
