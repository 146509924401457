import React from 'react'
// import s from './Footer.module.css'

export const Footer = () => {
    return (
        <footer>
            footer
        </footer>
    )
}
