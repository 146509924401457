import { IProject } from "../../../../types/project";
import s from "./Card.module.css";
import arrowGreen from "../../../../assets/Home/arrowGreen.svg";
import arrowPurple from "../../../../assets/Home/arrowPurple.svg";
import arrowBlue from "../../../../assets/Home/arrowBlue.svg";

interface CardProps {
  project: IProject;
  tech: string;
  size?: string;
}

export const Card: React.FC<CardProps> = ({ project, tech, size }) => {
  return (
    <a
      href={project.link}
      target="_blank"
      rel="noreferrer"
      className={`${s.card_container} ${
        size === "small"
          ? s.small
          : size === "medium"
          ? s.medium
          : size === "large"
          ? s.large
          : size === "xlarge"
          ? s.xlarge
          : size === "big"
          ? s.big
          : s.default
      }`}
    >
      <div className={s.card__wrapper}>
        <div className={s.card__info}>
          <div className={s.card__text}>
            <h2 className={s.card__title}>{project.title}</h2>
            <p className={s.card__description}>{project.description}</p>
          </div>
          <a
            href={project.link}
            target="_blank"
            rel="noreferrer"
            className={s.card__link}
          >
            {tech === "react" ? (
              <>
                <div className={s.card__link_react}>
                  <img src={arrowGreen} alt="arrowGreen" />
                </div>
              </>
            ) : tech === "webflow" ? (
              <div className={s.card__link_webflow}>
                <img src={arrowPurple} alt="arrowPurple" />
              </div>
            ) : tech === "wordpress" ? (
              <div className={s.card__link_wordpress}>
                <img src={arrowBlue} alt="arrowBlue" />
              </div>
            ) : (
              <div className={s.card__link_wordpress}>
                <img src={arrowBlue} alt="arrowBlue" />
              </div>
            )}
          </a>
        </div>
        <div className={s.card__img}>
          <img src={project.img} alt={project.title} />
        </div>
      </div>
    </a>
  );
};
